export const sidebarData = [
  {
    name: "Dashboard",
    src: "/sidebaricons/dashboard.svg",
    link: "/dashboard",
  },
  { name: "Assets", src: "/sidebaricons/assets.svg", link: "/assets" },
  {
    name: "Signals",
    src: "/sidebaricons/signals.svg",
    link: "/signals",
  },
  {
    name: "Markets",
    src: "/sidebaricons/markets.svg",
    link: "/markets",
  },
  { name: "Deposit", src: "/sidebaricons/deposit.svg", link: "/deposit" },
  {
    name: "Staking",
    src: "/sidebaricons/stake.svg",
    link: "/stake",
  },
  {
    name: "Withdrawal",
    src: "/sidebaricons/withdrawal.svg",
    link: "/withdraw",
  },

  // { name: "Buy Bitcoin", src: "/sidebaricons/buy.svg", link: "/buy" },
  // { name: "NFTs", src: "/sidebaricons/nfts.svg", link: "/nfts" },
  {
    name: "Subscription",
    src: "/sidebaricons/subscribe.svg",
    link: "/subscribe",
  },
  {
    name: "Copy Experts",
    src: "/sidebaricons/copy.svg",
    link: "/copy",
  },
  {
    name: "Verify Account",
    src: "/sidebaricons/verify.svg",
    link: "/verify",
  },
];

export const User = {
  isVerified: false,
};

export const depositMethods = [
  {
    name: "Pay with Crypto",
    icon: "/wallets/coinbase.png",
  },
  // {
  //   name: "Pay with Metamask",
  //   icon: "/wallets/metamask.webp",
  // },
  // {
  //   name: "Pay with Wallet",
  //   icon: "/wallets/walletconnect.png",
  // },
];

export const countryFlags = {
  Afghanistan: "🇦🇫",
  "Aland Islands": "🇦🇽",
  Albania: "🇦🇱",
  "American Samoa": "🇦🇸",
  Andorra: "🇦🇩",
  Angola: "🇦🇴",
  Anguilla: "🇦🇮",
  Antartica: "🇦🇶",
  "Antigua and Barbuda": "🇦🇬",
  Argentina: "🇦🇷",
  Armenia: "🇦🇲",
  Aruba: "🇦🇼",
  Austria: "🇦🇹",
  Australlia: "🇦🇺",
  Azerbaijan: "🇦🇿",
  Algeria: "🇩🇿",
  Bahrain: "🇧🇭",
  Bangladesh: "🇧🇩",
  Bahamas: "🇧🇸",
  Barbados: "🇧🇧",
  Belgium: "🇧🇪",
  Belarus: "🇧🇾",
  Belize: "🇧🇿",
  Benin: "🇧🇯",
  Bermuda: "🇧🇲",
  Bhutan: "🇧🇹",
  Bolivia: "🇧🇴",
  "Bonaire, Sint Eustatius and Saba": "🇧🇶",
  Botswana: "🇧🇼",
  "Bouvet Island": "🇧🇻",
  "Bosnia and Herzegovina": "🇧🇦",
  Brazil: "🇧🇷",
  "British Indian Ocean Territory": "🇮🇴",
  "Burkina Faso": "🇧🇫",
  Bulgeria: "🇧🇬",
  "Brunei Darussalam": "🇧🇳",
  Burundi: "🇧🇮",
  Canada: "🇨🇦",
  Cambodia: "🇰🇭",
  Cameroon: "🇨🇲",
  "Cape Verde": "🇨🇻",
  "Cayman Islands": "🇰🇾",
  "Central African Republic": "🇨🇫",
  Chad: "🇹🇩",
  Chile: "🇨🇱",
  China: "🇨🇳",
  "Christmas Island": "🇨🇽",
  "Cocos (Keeling) Islands": "🇨🇨",
  "Cook Islands": "🇨🇰",
  Colombia: "🇨🇴",
  Comoros: "🇰🇲",
  "Côte D'Ivoire": "🇨🇮",
  "Costa Rica": "🇨🇷",
  Cuba: "🇨🇺",
  Curacao: "🇨🇼",
  Cyprus: "🇨🇾",
  Croatia: "🇭🇷",
  "Czech Republic": "🇨🇿",
  Denmark: "🇩🇰",
  "Democratic Republic of Congo": "🇨🇩",
  Djibouti: "🇩🇯",
  Dominica: "🇩🇲",
  "Dominican Republic": "🇩🇴",
  Ecuador: "🇪🇨",
  Egypt: "🇪🇬",
  "El Salvador": "🇸🇻",
  "Equatorial Guinea": "🇬🇶",
  Eritrea: "🇪🇷",
  Estonia: "🇪🇪",
  Ethiopia: "🇪🇹",
  "Falkland Islands (Malvinas)": "🇫🇰",
  "Faroe Islands": "🇫🇴",
  Finland: "🇫🇮",
  Fiji: "🇫🇯",
  "French Southern Territories": "🇹🇫",
  France: "🇫🇷",
  "French Guiana": "🇬🇫",
  "French Polynesia": "🇵🇫",
  Gabon: "🇬🇦",
  Gambia: "🇬🇲",
  Georgia: "🇬🇪",
  Germany: "🇩🇪",
  Ghana: "🇬🇭",
  Gibraltar: "🇬🇮",
  Greece: "🇬🇷",
  Greenland: "🇬🇱",
  Grenada: "🇬🇩",
  Guadeloupe: "🇬🇵",
  Guam: "🇬🇺",
  Guatemala: "🇬🇹",
  Guernsey: "🇬🇬",
  "Guinea-Bissau": "🇬🇼",
  Guinea: "🇬🇳",
  Guyana: "🇬🇾",
  Haiti: "🇭🇹",
  "Heard Island and Mcdonald Islands": "🇭🇲",
  Honduras: "🇭🇳",
  "Hong Kong": "🇭🇰",
  Hungary: "🇭🇺",
  Iceland: "🇮🇸",
  Indonesia: "🇮🇩",
  India: "🇮🇳",
  Iraq: "🇮🇶",
  Ireland: "🇮🇪",
  Iran: "🇮🇷",
  Israel: "🇮🇱",
  "Isle of Man": "🇮🇲",
  Italy: "🇮🇹",
  Jamaica: "🇯🇲",
  Jersey: "🇯🇪",
  Japan: "🇯🇵",
  Jordan: "🇯🇴",
  Kazakhstan: "🇰🇿",
  Kenya: "🇰🇪",
  Kiribati: "🇰🇮",
  Kuwait: "🇰🇼",
  Kyrgyzstan: "🇰🇬",
  "Lao People's Democratic Republic": "🇱🇦",
  Lebanon: "🇱🇧",
  Latvia: "🇱🇻",
  Lesotho: "🇱🇸",
  Liberia: "🇱🇷",
  Libya: "🇱🇾",
  Liechtenstein: "🇱🇮",
  Lithuania: "🇱🇹",
  Luxembourg: "🇱🇺",
  Macedonia: "🇲🇰",
  Madagascar: "🇲🇬",
  Macao: "🇲🇴",
  Mali: "🇲🇱",
  Malta: "🇲🇹",
  Mauritius: "🇲🇺",
  Maldives: "🇲🇻",
  Malawi: "🇲🇼",
  Malaysia: "🇲🇾",
  "Marshall Islands": "🇲🇭",
  Martinique: "🇲🇶",
  Mauritania: "🇲🇷",
  Mayotte: "🇾🇹",
  Mexico: "🇲🇽",
  Micronesia: "🇫🇲",
  Moldova: "🇲🇩",
  Morocco: "🇲🇦",
  Monaco: "🇲🇨",
  Montenegro: "🇲🇪",
  Mongolia: "🇲🇳",
  Montserrat: "🇲🇸",
  Mozambique: "🇲🇿",
  Myanmar: "🇲🇲",
  Namibia: "🇳🇦",
  Nauru: "🇳🇷",
  Nepal: "🇳🇵",
  Netherlands: "🇳🇱",
  "New Caledonia": "🇳🇨",
  "New Zealand": "🇳🇿",
  "North Korea": "🇰🇵",
  Nicaragua: "🇳🇮",
  Niger: "🇳🇪",
  Nigeria: "🇳🇬",
  Niue: "🇳🇺",
  "Norfolk Island": "🇳🇫",
  "Northern Mariana Islands": "🇲🇵",
  Norway: "🇳🇴",
  Oman: "🇴🇲",
  Pakistan: "🇵🇰",
  Palau: "🇵🇼",
  "Palestinian Territory": "🇵🇸",
  Panama: "🇵🇦",
  "Papua New Guinea": "🇵🇬",
  Paraguay: "🇵🇾",
  Peru: "🇵🇪",
  Philippines: "🇵🇭",
  Pitcairn: "🇵🇳",
  Poland: "🇵🇱",
  Portugal: "🇵🇹",
  "Puerto Rico": "🇵🇷",
  Qatar: "🇶🇦",
  "Republic of the Congo": "🇨🇬",
  Reunion: "🇷🇪",
  Romania: "🇷🇴",
  Russia: "🇷🇺",
  Rwanda: "🇷🇼",
  Samoa: "🇼🇸",
  "Saint Barthelemy": "🇧🇱",
  "Saint Lucia": "🇱🇨",
  "Saint Martin (French Part)": "🇲🇫",
  "Saint Pierre and Miquelon": "🇵🇲",
  "Saudi Arabia": "🇸🇦",
  "Saint Helena, Ascension and Tristan Da Cunha": "🇸🇭",
  "San Marino": "🇸🇲",
  "South Georgia": "🇬🇸",
  Seychelles: "🇸🇨",
  Serbia: "🇷🇸",
  "Solomon Islands": "🇸🇧",
  "Sri Lanka": "🇱🇰",
  Sudan: "🇸🇩",
  Sweden: "🇸🇪",
  Singapore: "🇸🇬",
  Slovenia: "🇸🇮",
  Slovakia: "🇸🇰",
  "Svalbard and Jan Mayen": "🇸🇯",
  "Sierra Leone": "🇸🇱",
  Senegal: "🇸🇳",
  Somalia: "🇸🇴",
  Suriname: "🇸🇷",
  "South Sudan": "🇸🇸",
  "Sao Tome and Principe": "🇸🇹",
  "Syrian Arab Republic": "🇸🇾",
  Swaziland: "🇸🇿",
  "South Africa": "🇿🇦",
  "Sint Maarten (Dutch Part)": "🇸🇽",
  "Saint Vincent and The Grenadines": "🇻🇨",
  Spain: "🇪🇸",
  "Saint Kitts and Nevis": "🇰🇳",
  "South Korea": "🇰🇷",
  Switzerland: "🇨🇭",
  Taiwan: "🇹🇼",
  Tanzania: "🇹🇿",
  Thailand: "🇹🇭",
  Togo: "🇹🇬",
  Tajikistan: "🇹🇯",
  Tokelau: "🇹🇰",
  "Turks and Caicos Islands": "🇹🇨",
  "Timor-Leste": "🇹🇱",
  Turkmenistan: "🇹🇲",
  Tunisia: "🇹🇳",
  Tonga: "🇹🇴",
  Turkey: "🇹🇷",
  "Trinidad and Tobago": "🇹🇹",
  Tuvalu: "🇹🇻",
  Ukraine: "🇺🇦",
  Uganda: "🇺🇬",
  "United Arab Emirates": "🇦🇪",
  "United Kingdom": "🇬🇧",
  "United States Minor Outlying Islands": "🇺🇲",
  "United States": "🇺🇸",
  Uruguay: "🇺🇾",
  Uzbekistan: "🇺🇿",
  "Vatican City": "🇻🇦",
  Venezuela: "🇻🇪",
  "Virgin Islands, British": "🇻🇬",
  "Virgin Islands, U.S.": "🇻🇮",
  "Viet Nam": "🇻🇳",
  Vanuatu: "🇻🇺",
  "Wallis and Futuna": "🇼🇫",
  "Western Sahara": "🇪🇭",
  Yemen: "🇾🇪",
  Zambia: "🇿🇲",
  Zimbabwe: "🇿🇲",
};
