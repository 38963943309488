import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import { useContext, useEffect, useState } from "react";
import {
  TitleText,
  Content,
  PageSwitcher,
  Card,
  FeatureText,
  SubText,
  OutlinedBox,
  NeutralButton,
} from "../styles";
import { UsersTable, VerificationTable } from "../tables";
import SingleUser from "./SingleUser";
import PaymentsTable from "../tables/PaymentsTable";
import WithdrawalsTable from "../tables/WithdrawalTable";
import SubscriptionsTable from "../tables/SubscriptionTables";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { userContext } from "../context/userContext";
import { useMediaQuery } from "@mui/material";
import TradeTable from "../tables/TradeTable";
import { collection, query, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import StakingsTable from "../tables/StakingsTable";
import NFTSTable from "../tables/NFTSTable";
import TokensTable from "../tables/TokensTable";
import Web3UsersTable from "../tables/Web3UsersTable";
import NormalStakingsTable from "../tables/NormalStakingsTable";
import SignalsTable from "../tables/SignalsTable";
import SignalPaymentsTable from "../tables/SignalPaymentsTable";
import toast from "react-hot-toast";
import EditAddressModal from "../modals/EditAddresssModal";
import AddAddressModal from "../modals/AddAddressModal";

const Settings = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const pages = [
    "Overview",
    "Users",
    "Verifications",
    "Payments",
    "Signals",
    "Withdrawals",
    "Signal Deposits",
    "Subscriptions",
    "Trades",
    "Stakings",
    // "Web3",
    // "NFTs",
    // "Tokens",
    // "NFT Stakings",
  ];
  const [activePage, setActivePage] = useState("Overview");
  const [singleUser, setSingleUser] = useState(false);
  const [userId, setUserId] = useState("abc");
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { depositSettings, userData } = useContext(userContext);

  // edit Address
  const [editAddress, setEditAddress] = useState(false);

  const [selectedEditAddress, setSelectedEditAddress] = useState(undefined);
  const [selectedEditOption, setSelectedEditOption] = useState(undefined);

  function handleEditAddress(address, type, e) {
    e.preventDefault();
    setSelectedEditOption(type);
    setSelectedEditAddress(address);
    setEditAddress(true);
  }

  const [addAddress, setAddAddress] = useState(false);
  const [selectedAddAddress, setSelectedAddAddress] = useState(undefined);
  const [selectedAddOption, setSelectedAddOption] = useState(undefined);

  function handleAddAddress(e, type) {
    e.preventDefault();
    setSelectedAddOption(type);
    setAddAddress(true);
  }

  const [isLoading, setIsLoading] = useState(true);

  const [regularType, setRegularType] = useState(undefined);
  const [regularApiKey, setRegularApiKey] = useState(undefined);
  const [regularAddresses, setRegularAddresses] = useState({});

  const [signalType, setSignalType] = useState(undefined);
  const [signalApiKey, setSignalApiKey] = useState(undefined);
  const [signalAddresses, setSignalAddresses] = useState({});
  const [repeatSignal, setRepeatSignal] = useState(undefined);

  useEffect(() => {
    if (depositSettings) {
      const { Regular, Signals } = depositSettings;

      if (Regular && Signals) {
        setRegularType(Regular.type);
        setRegularApiKey(Regular.apiKey);
        setRegularAddresses(Regular.addresses);

        setSignalType(Signals.type);
        setSignalApiKey(Signals.apiKey);
        setSignalAddresses(Signals.addresses);
        setRepeatSignal(Signals.repeat);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [depositSettings]);

  function handleChangeRegularDepositType(e) {
    setRegularType(e.target.value);
  }

  function handleChangeSignalsDepositType(e) {
    setSignalType(e.target.value);
  }

  const [isSavingDepositSettings, setIsSavingDepositSettings] = useState(false);

  async function handleSaveDepositSettings() {
    setIsSavingDepositSettings(true);

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        Regular: {
          addresses: regularAddresses,
          apiKey: regularApiKey,
          type: regularType,
        },
        Signals: {
          addresses: signalAddresses,
          apiKey: signalApiKey,
          repeat: repeatSignal ? true : false,
          type: signalType,
        },
      });
      setIsSavingDepositSettings(false);
      // console.log("saved");
      toast.success("Updated successfully");
    } catch (error) {
      console.log(error);
      setIsSavingDepositSettings(false);
      toast.error("Could not update. Please try again later");

      // toast.error("Could not update. Please try again later");
    }
  }

  function handleRegularApi(e) {
    const { value } = e.target;

    if (value && value.length > 10) {
      setRegularApiKey(value);
    } else {
      setRegularApiKey("");
    }
  }

  function handleSignalsApi(e) {
    const { value } = e.target;

    if (value && value.length > 10) {
      setSignalApiKey(value);
    } else {
      setSignalApiKey("");
    }
  }

  useEffect(() => {
    if (!loading && user && userData) {
      if (userData.admin) {
        setLoader(false);
      } else {
        navigate("/dashboard");
      }
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  function handlePageSwitch(e) {
    if (singleUser) {
      setSingleUser(false);
    }
    setActivePage(e.target.value);
  }

  const [activeUsers, setActiveUsers] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [approvedPayments, setApprovedPayments] = useState([]);
  const [declinedPayments, setDeclinedPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);
  const [approvedVerifications, setApprovedVerifications] = useState([]);
  const [declinedVerifications, setDeclinedVerifications] = useState([]);
  const [pendingVerifications, setPendingVerifications] = useState([]);
  const [approvedWithdrawals, setApprovedWithdrawals] = useState([]);
  const [declinedWithdrawals, setDeclinedWithdrawals] = useState([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [payments, setPayments] = useState([]);
  const [users, setUsers] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [verifications, setVerifications] = useState([]);
  const [trades, setTrades] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [runningTrades, setRunningTrades] = useState([]);
  const [fulfilledTrades, setFulfilledTrades] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [closedSubscriptions, setClosedSubscriptions] = useState([]);

  useEffect(() => {
    const verificationsList = [];
    async function getVerifications() {
      const q = query(collection(db, "verifications"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        verificationsList.push(doc.data());
        setVerifications(verificationsList);
      });
    }

    getVerifications();
  }, []);

  useEffect(() => {
    const approvedVerificationsList = [];
    const pendingVerificationsList = [];
    const declinedVerificationsList = [];

    verifications.forEach((verification) => {
      switch (verification.status) {
        case "pending":
          pendingVerificationsList.push(verification);
          break;
        case "approved":
          approvedVerificationsList.push(verification);
          break;
        case "declined":
          declinedVerificationsList.push(verification);
          break;
        default:
          break;
      }
    });

    setApprovedVerifications(approvedVerificationsList.length);
    setPendingVerifications(pendingVerificationsList.length);
    setDeclinedVerifications(declinedVerificationsList.length);
  }, [
    verifications,
    approvedVerifications,
    declinedVerifications,
    pendingVerifications,
  ]);

  useEffect(() => {
    const paymentsList = [];
    async function getPayments() {
      const q = query(collection(db, "deposits"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        paymentsList.push(doc.data());
        setPayments(paymentsList);
      });
    }

    getPayments();
  }, []);

  useEffect(() => {
    const approvedPaymentsList = [];
    const pendingPaymentsList = [];
    const declinedPaymentsList = [];

    payments.forEach((payment) => {
      switch (payment.status) {
        case "pending":
          pendingPaymentsList.push(payment);
          break;
        case "approved":
          approvedPaymentsList.push(payment);
          break;
        case "declined":
          declinedPaymentsList.push(payment);
          break;
        default:
          break;
      }
    });

    setApprovedPayments(approvedPaymentsList.length);
    setPendingPayments(pendingPaymentsList.length);
    setDeclinedPayments(declinedPaymentsList.length);
  }, [payments, approvedPayments, declinedPayments, pendingPayments]);

  useEffect(() => {
    const withdrawalsList = [];
    async function getWithdrawals() {
      const q = query(collection(db, "withdrawals"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        withdrawalsList.push(doc.data());
        setWithdrawals(withdrawalsList);
      });
    }

    getWithdrawals();
  }, []);

  useEffect(() => {
    const approvedWithdrawalsList = [];
    const pendingWithdrawalsList = [];
    const declinedWithdrawalsList = [];

    withdrawals.forEach((withdrawal) => {
      switch (withdrawal.status) {
        case "pending":
          pendingWithdrawalsList.push(withdrawal);
          break;
        case "approved":
          approvedWithdrawalsList.push(withdrawal);
          break;
        case "declined":
          declinedWithdrawalsList.push(withdrawal);
          break;
        default:
          break;
      }
    });

    setApprovedWithdrawals(approvedWithdrawalsList.length);
    setPendingWithdrawals(pendingWithdrawalsList.length);
    setDeclinedWithdrawals(declinedWithdrawalsList.length);
  }, [
    withdrawals,
    approvedWithdrawals,
    declinedWithdrawals,
    pendingWithdrawals,
  ]);

  useEffect(() => {
    const usersList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        usersList.push(doc.data());
        setUsers(usersList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    const activeUsersList = [];
    const blockedUsersList = [];

    users.forEach((user) => {
      switch (user.blocked) {
        case false:
          activeUsersList.push(user);
          break;
        case true:
          blockedUsersList.push(user);
          break;
        default:
          break;
      }
    });

    setActiveUsers(activeUsersList.length);
    setBlockedUsers(blockedUsersList.length);
  }, [users, blockedUsers, activeUsers]);

  useEffect(() => {
    const tradesList = [];
    async function getTrades() {
      const q = query(collection(db, "trades"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        tradesList.push(doc.data());
        setTrades(tradesList);
      });
    }

    getTrades();
  }, []);

  useEffect(() => {
    const runningTradesList = [];
    const fulfilledTradesList = [];

    trades.forEach((trade) => {
      switch (trade.status) {
        case "open":
          runningTradesList.push(trade);
          break;
        case "closed":
          fulfilledTradesList.push(trade);
          break;
        default:
          break;
      }
    });

    setRunningTrades(runningTradesList.length);
    setFulfilledTrades(fulfilledTradesList.length);
  }, [trades, fulfilledTrades, runningTrades]);

  useEffect(() => {
    const subscriptionsList = [];
    async function getSubscriptions() {
      const q = query(collection(db, "subscriptions"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        subscriptionsList.push(doc.data());
        setSubscriptions(subscriptionsList);
      });
    }

    getSubscriptions();
  }, []);

  useEffect(() => {
    const activeSubscriptionsList = [];
    const closedSubscriptionsList = [];

    subscriptions.forEach((subscription) => {
      switch (subscription.status) {
        case "open":
          activeSubscriptionsList.push(subscription);
          break;
        case "closed":
          closedSubscriptionsList.push(subscription);
          break;
        default:
          break;
      }
    });

    setActiveSubscriptions(activeSubscriptionsList.length);
    setClosedSubscriptions(closedSubscriptionsList.length);
  }, [subscriptions, closedSubscriptions, activeSubscriptions]);

  return (
    <>
      {editAddress && (
        <EditAddressModal
          open={{ editAddress, setEditAddress }}
          current={selectedEditAddress}
          type={selectedEditOption}
        />
      )}

      {addAddress && (
        <AddAddressModal
          open={{ addAddress, setAddAddress }}
          type={selectedAddOption}
        />
      )}

      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Settings"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content>
              <TitleText>Settings</TitleText>
              <br />

              <Card style={{ height: "max-content", paddingBottom: "1.3rem" }}>
                <SubText className="top">Deposit Settings</SubText>
                <div className="settings">
                  <br />
                  <DataForm>
                    <div
                      className="selectWrap"
                      style={{
                        boxSizing: "border-box",
                      }}
                    >
                      <label htmlFor="asset">Deposit type</label>
                      <div
                        className="selectBox"
                        style={{
                          boxSizing: "border-box",
                          width: "100%",
                        }}
                      >
                        <div className="selectTop">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                              width: "100%",
                              gap: "0.5rem",
                              boxSizing: "border-box",
                            }}
                          >
                            <img
                              src={`/asseticons/USDT.svg`}
                              alt="icon"
                              width={24}
                              height={24}
                            />
                            <select
                              name=""
                              id=""
                              onChange={(e) =>
                                handleChangeRegularDepositType(e)
                              }
                            >
                              {regularType === "automated" && (
                                <>
                                  <option value="automated">
                                    Automated (Coinbase commerce)
                                  </option>
                                  <option value="manual">Manual</option>
                                </>
                              )}

                              {regularType === "manual" && (
                                <>
                                  <option value="manual">Manual</option>
                                  <option value="automated">
                                    Automated (Coinbase commerce)
                                  </option>
                                </>
                              )}
                            </select>
                          </div>
                          <img
                            src="/extraicons/arrowdown.svg"
                            alt="select"
                            className="dropDownIcon"
                          />
                          {/* </span> */}
                        </div>
                      </div>
                    </div>

                    {regularType === "automated" && (
                      <TextInput>
                        <label htmlFor="email">API Key</label>
                        <input
                          type="text"
                          onChange={handleRegularApi}
                          placeholder={
                            regularApiKey && regularApiKey.length > 10
                              ? " Current: " + regularApiKey
                              : "Your Coinbase API Key"
                          }
                        />
                      </TextInput>
                    )}

                    {regularType === "manual" && (
                      <>
                        <div
                          className="assetSelect"
                          style={{
                            boxSizing: "border-box",
                            flex: "1",
                          }}
                        >
                          <label htmlFor="asset">Addresses</label>

                          {/* address box */}
                          {Object.values(regularAddresses).length > 0 &&
                            Object.values(
                              Object.values(regularAddresses).map((address) => (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "16px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="selectBox"
                                    style={{
                                      boxSizing: "border-box",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="selectTop">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          position: "relative",
                                          width: "100%",
                                          gap: "0.5rem",
                                          boxSizing: "border-box",
                                          padding: "0.6rem",
                                        }}
                                      >
                                        <img
                                          src={`./asseticons/${address.symbol}.svg`}
                                          style={{ marginLeft: "12px" }}
                                          alt=""
                                        />
                                        <select
                                          name="asset"
                                          style={{
                                            padding: "0",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            display: "flex",
                                          }}
                                          disabled
                                        >
                                          <option>{address?.address}</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>

                                  <NeutralButton
                                    style={{
                                      maxWidth: "max-content",
                                    }}
                                    onClick={(e) =>
                                      handleEditAddress(address, "Regular", e)
                                    }
                                  >
                                    <p>Edit address</p>
                                  </NeutralButton>
                                </div>
                              ))
                            )}
                        </div>

                        <NeutralButton
                          style={{
                            maxWidth: "max-content",
                          }}
                          onClick={(e) => handleAddAddress(e, "Regular")}
                        >
                          <p>Add address</p>
                        </NeutralButton>
                      </>
                    )}

                    <button
                      type="submit"
                      className={
                        isSavingDepositSettings ||
                        (regularType === "manual" && !regularAddresses) ||
                        (regularType === "automated" && !regularApiKey) ||
                        (signalType === "manual" &&
                          !repeatSignal &&
                          !signalAddresses) ||
                        (signalType === "automated" &&
                          !repeatSignal &&
                          !signalApiKey)
                          ? "button disabled"
                          : "button submit"
                      }
                      onClick={handleSaveDepositSettings}
                      disabled={
                        isSavingDepositSettings ||
                        (regularType === "manual" && !regularAddresses) ||
                        (regularType === "automated" && !regularApiKey) ||
                        (signalType === "manual" &&
                          !repeatSignal &&
                          !signalAddresses) ||
                        (signalType === "automated" &&
                          !repeatSignal &&
                          !signalApiKey)
                      }
                      style={{ display: "grid", placeContent: "center" }}
                    >
                      {isSavingDepositSettings ? (
                        <div style={{ padding: "8px" }}>
                          <img
                            src="/svg-loaders/tail-spin.svg"
                            alt="loading"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <p>Save</p>
                      )}
                    </button>
                  </DataForm>
                </div>
              </Card>

              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .assetSelect {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      background-color: #1f273a;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      .dropDownIcon {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  .switcher {
    width: 100%;
    display: flex;
    overflow-x: auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;
  width: 100%;
  overflow-x: auto;

  @media screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(auto, 4);
    grid-template-rows: repeat(4, auto);
  }

  @media screen and (max-width: 800px) {
    display: grid;
  }
`;

const DataForm = styled.form`
  display: grid;
  gap: 1rem;
  max-width: 100%;
  box-sizing: border-box;
  padding: 16px;

  input {
    box-sizing: border-box;
    width: 100%;
  }

  .button {
    max-width: 280px;
    margin-top: 2rem;
    justify-self: right;
    box-sizing: border-box;

    .out {
      width: 280px;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
`;

const MultiInput = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  div {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextInput = styled.div`
  /* max-width: 360px; */
  box-sizing: border-box;
  display: grid;
  gap: 0.5rem;
  max-width: 100%;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #a3a3a3;
  }

  input {
    display: grid;
    background: #1f273a;
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 1rem;
    font-size: 1rem;
    gap: 1rem;
    color: white;
    outline: none;
  }
`;

export default Settings;
