import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, OutlinedBox, SubText } from "../styles";
import styled from "styled-components";
import { userContext } from "../context/userContext";
import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";
import CircularLoader from "../pages/CircularLoader";
import { useMoralis } from "react-moralis";
import Moralis from "moralis-v1";
import emailjs from "@emailjs/browser";

const DepositMetamaskModal = ({ open, user }) => {
  const { depositMetamask, setDepositMetamask } = open;
  const { accounts, currentPrices } = useContext(userContext);
  const [fiatAccount, setFiatAccount] = useState({});
  const [amount, setAmount] = useState("");
  const [isPaying, setIsPaying] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [minimumError, setMinimumError] = useState(false);
  const AmountRef = useRef();
  const [isWaiting, setIsWaiting] = useState(false);
  const { user: currentUser, logout, authenticate } = useMoralis();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [address, setAddress] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const id = user.uid;
  const { userData } = useContext(userContext);

  // useEffect(() => {
  //   if (currentUser) {
  //     logout();
  //   }
  // }, []);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Crypto } = live;
        const { ETH } = Crypto;
        setFiatAccount(ETH);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (currentUser) {
      setAddress(currentUser.get("ethAddress"));
    }
  }, [currentUser]);

  const login = async () => {
    setIsSigningIn(true);
    setTimeout(() => {
      if (isSigningIn) {
        setIsSigningIn(false);
      }
    }, 1000);
    try {
      const authUser = await Moralis.authenticate({
        provider: "metamask",
        signingMessage: "Sign in with your wallet to make a deposit.",
      });
      if (authUser) {
        const userAddress = authUser.get("ethAddress");
        setAddress(userAddress);
        setIsSigningIn(false);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsSigningIn(false);
    }
  };

  function handleTransfer() {
    setIsPaying(true);
    handlePayment();
  }

  async function handlePayment() {
    await Moralis.transfer({
      type: "native",
      amount: Moralis.Units.ETH(Number(amount)),
      receiver: "0x778053e8DA3c5fd206CbD600bEe39Ec676FFE1b1",
    })
      .then(() => {
        callToTransfer();
      })
      .catch((error) => {
        if (error.code === "INSUFFICIENT_FUNDS") {
          toast.error("You do not have sufficient funds for this transaction.");
        } else {
          toast.error("Payment could not complete.");
        }
        setIsPaying(false);
      });
  }

  // changes the status of the deposit doc and calls to increment the user's balance
  async function callToTransfer() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "deposits", str), {
      ref: str,
      user: id,
      status: "approved",
      date: serverTimestamp(),
      amount: Number(amount),
      type: "ETH",
      method: "Metamask",
    })
      .then(() => {
        handleUserApprove();
      })
      .catch((error) => {
        setIsPaying(false);
        toast.error("Payment could not complete.");
        return;
      });
  }

  // Increments the user account balance and calls to send notification
  async function handleUserApprove() {
    const q = doc(db, "accounts", id);
    const key = `live.Crypto.ETH.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(amount)),
      }).then(() => {
        sendApproveEmail();
      });
    } catch (error) {
      setIsPaying(false);
      toast.error("Payment could not complete.");
      return;
    }
  }

  // Approval Email and calls to change doc status
  async function sendApproveEmail() {
    const params = {
      action: "Deposit",
      final: "Completed",
      user_name: userData?.firstname,
      amount: amount,
      logo_link: "https://primefintech.net/logo.png",
      action_name: "deposit",
      asset: "ETH",
      to_or_from: "to",
      from_name: "Prime Fintech",
      accountType: "crypto",
      message: "has been completed",
      detail: `${amount} ETH should be in your account soon.`,
      broker_web: "https://primefintech.net",
      broker_web_to: "primefintech.net",
      broker_email_to: "mailto:support@primefintech.net",
      to_email: userData?.email,
      from_email: "support@primefintech.net",
      reply_to: "support@primefintech.net",
    };
    emailjs
      .send("service_an0xam1", "template_vck7wtq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        setApproveNotification();
      })
      .catch((error) => {
        setIsPaying(false);
        toast.error("Payment could not complete.");
        return;
      });
  }

  //send approve notification
  async function setApproveNotification() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();
    const amountUSD = Number(currentPrices["ETH"] * amount).toFixed(2);

    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user: id,
      title: "Deposit",
      text: "Your deposit of $" + amountUSD + " was successful.",
    })
      .then(() => {
        setIsPaying(true);
        toast.success("Payment successfully made.");
      })
      .catch((error) => {
        setIsPaying(false);
        toast.error("Payment could not complete.");
        return;
      });
  }

  function disconnect() {
    logout();
    setIsAuthenticated(false);
  }

  return (
    <Modal
      open={depositMetamask}
      onClose={() => setDepositMetamask(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
        // backgroundColor: "gray",
      }}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Pay with Metamask</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setDepositMetamask(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        {!isAuthenticated && (
          <ModalContent>
            <OutlinedBox>
              <p className="infot">
                You need to connect your wallet to continue.
              </p>
            </OutlinedBox>
            <br />
            <button
              style={{
                display: "grid",
                placeContent: "center",
                padding: "0.72rem",
              }}
              className="actionButton"
              disabled={isSigningIn}
              onClick={login}
            >
              {isSigningIn ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              ) : (
                <p className="actionText">Connect Wallet</p>
              )}
            </button>
            <br />
          </ModalContent>
        )}
        {isAuthenticated && (
          <ModalContent>
            {isWaiting ? (
              <CircularLoader />
            ) : (
              <>
                <button onClick={disconnect} className="disconnectSwitch">
                  <div className="disconnectLeft">
                    <p>Connected as</p>
                    <p>
                      {address.substring(0, 5)}....
                      {address.substring(address.length - 4)}
                    </p>
                  </div>

                  <div className="actionButton">
                    <p className="actionText">Disconnect</p>
                  </div>
                </button>

                <OutlinedBox>
                  <p className="infot">
                    This deposit will be available in your live ETH account.
                  </p>
                </OutlinedBox>
                <br />

                <div className="amWrap">
                  <label htmlFor="amount">Amount</label>
                  <div className="amBx">
                    <div className="amTop">
                      <input
                        placeholder="1000"
                        name="amount"
                        type="number"
                        ref={AmountRef}
                        onChange={handleAmount}
                      />
                      <span className="coinSelector">
                        <img src={`/asseticons/ETH.svg`} alt="coin" />
                        <p>ETH</p>
                      </span>
                    </div>

                    <div className="captions">
                      <p className="balance">
                        Balance ~ {""}
                        <span
                          className={fiatAccount.value < 1 ? "red" : "green"}
                        >
                          {Math.floor(fiatAccount.value)} ETH
                        </span>
                      </p>
                      {amount && (
                        <p className="extra">
                          Total
                          <span className="green">
                            {" "}
                            ~{Number(amount).toFixed(2)} ETH
                          </span>
                        </p>
                      )}
                    </div>
                    <br />
                    {minimumError && (
                      <p
                        className="red warning textMedium"
                        style={{ marginTop: "1rem" }}
                      >
                        The minimum deposit is 0.5 ETH
                      </p>
                    )}
                  </div>
                </div>

                <br />

                <button
                  style={{ display: "grid", placeContent: "center" }}
                  disabled={!amount || minimumError || isPaying || isDisabled}
                  onClick={handleTransfer}
                  className={
                    !amount || minimumError || isPaying || isDisabled
                      ? "button disabled"
                      : "button submit"
                  }
                >
                  {isPaying ? (
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      style={{
                        padding: "10px",
                        width: "20px",
                        height: "auto",
                      }}
                    />
                  ) : (
                    <p>Pay with Metamask</p>
                  )}
                </button>
              </>
            )}
          </ModalContent>
        )}
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .disconnectSwitch {
    background: #0e121b;
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1rem;
    max-width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    .disconnectLeft {
      display: grid;
      /* gap: 0.8rem; */
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default DepositMetamaskModal;
