import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import {
  deleteField,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useRef } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const AddAddressModal = ({ open, type }) => {
  const { addAddress, setAddAddress } = open;
  const [isChanging, setIsChanging] = useState(false);

  const [name, setName] = useState(undefined);
  const [symbol, setSymbol] = useState(undefined);

  const [qr, setQR] = useState(undefined);
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [tempUrl, setTempUrl] = useState("");

  const [address, setAddress] = useState(undefined);
  function handleAddress(e) {
    const { value } = e.target;

    if (value) {
      setAddress(value);
    } else {
      setAddress("");
    }
  }

  const cryptoAssets = [
    {
      asset: "BTC",
      name: "Bitcoin",
    },
    {
      asset: "ETH",
      name: "Ethereum",
    },
    {
      asset: "SOL",
      name: "Solana",
    },
    {
      asset: "BCH",
      name: "Bitcoin Cash",
    },
    {
      asset: "LTC",
      name: "Litecoin",
    },
    {
      asset: "DOGE",
      name: "Dogecoin",
    },
    {
      asset: "USDT",

      name: "Tether",
    },
    {
      asset: "MATIC",

      name: "Polygon",
    },
    {
      asset: "AVAX",

      name: "Avalanche",
    },
    {
      asset: "USDC",

      name: "USD Coin",
    },
    {
      asset: "AAVE",

      name: "AAVE",
    },
    {
      asset: "ALGO",

      name: "Algorand",
    },
    {
      asset: "ANC",

      name: "Anchor Protocol",
    },
    {
      asset: "APE",

      name: "ApeCoin",
    },
    {
      asset: "AURORA",

      name: "Aurora",
    },
    {
      asset: "AXS",

      name: "Axie Infinity",
    },
    {
      asset: "BTG",

      name: "Bitcoin Gold",
    },
    {
      asset: "BORING",

      name: "Boring DAO",
    },
    {
      asset: "ADA",

      name: "Cardano",
    },
    {
      asset: "XCN",

      name: "Onyxcoin",
    },
    {
      asset: "LINK",

      name: "ChainLink",
    },

    {
      asset: "CRO",

      name: "Cronos",
    },
    {
      asset: "DAI",

      name: "Dai",
    },
    {
      asset: "DASH",

      name: "Dash",
    },
    {
      asset: "MANA",

      name: "Decentraland",
    },

    {
      asset: "ETC",

      name: "Ethereum Classic",
    },
    {
      asset: "EVMOS",

      name: "Evmos",
    },
    {
      asset: "GT",

      name: "Gate Token",
    },

    {
      asset: "LN",

      name: "Link",
    },
    {
      asset: "XMR",

      name: "Monero",
    },
    {
      asset: "NEXO",

      name: "Nexo",
    },
    {
      asset: "OKB",

      name: "OKB",
    },
    {
      asset: "OP",

      name: "Optimism",
    },
    {
      asset: "OGN",

      name: "Origin Protocol",
    },
    {
      asset: "ORN",

      name: "Orion Protocol",
    },
    {
      asset: "DOT",

      name: "Polkadot",
    },
    {
      asset: "XPR",

      name: "Proton",
    },

    {
      asset: "RARI",

      name: "Rarible",
    },
    {
      asset: "SFP",

      name: "Safepal",
    },
    {
      asset: "SHIB",

      name: "Shiba Inu",
    },
    {
      asset: "XLM",

      name: "Stellar",
    },

    {
      asset: "GMT",

      name: "Stepn",
    },
    {
      asset: "SUSHI",
      name: "Sushi",
    },
    {
      asset: "TLOS",

      name: "Telos",
    },
    {
      asset: "XTZ",

      name: "Tezos",
    },
    {
      asset: "GRT",

      name: "The Graph",
    },
    {
      asset: "TRX",

      name: "Tron",
    },
    {
      asset: "UNI",
      name: "Uniswap",
    },
    {
      asset: "VET",

      name: "Vechain",
    },
    {
      asset: "WING",

      name: "Wing Finance",
      type: "Crypto",
    },

    {
      asset: "ZEC",

      name: "Zcash",
    },

    {
      asset: "XRP",

      name: "Ripple",
    },
  ].sort();

  const [selectedAsset, setSelectedAsset] = useState({
    asset: "BTC",
    name: "Bitcoin",
  });

  function handleAssetChange(e) {
    const { value } = e.target;

    cryptoAssets.forEach((asset) => {
      if (asset.asset === value) {
        setSelectedAsset(asset);
      }
    });
  }

  const imageRef = useRef();

  function handleImageChange() {
    imageRef.current.click();
  }

  const [isSubmittingQR, setIsSubmittingQR] = useState(false);

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
      const url = URL.createObjectURL(file);
      setTempUrl(url);
    }
  }

  function reset() {
    URL.revokeObjectURL(imageLink);
  }

  const [isSaving, setIsSaving] = useState(false);

  async function handleSave() {
    setIsSaving(true);

    if (imageLink) {
      submitQR();
    } else {
      addDepositAddress();
    }
  }

  async function submitQR() {
    // setIsUploadingVerification(true);
    if (imageLink) {
      const storageRef = ref(storage, imageName + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            // submitVerification(downloadURL);
            addDepositAddressQR(downloadURL);
          });
        }
      );
    }
  }

  async function addDepositAddressQR(url) {
    let namekey;

    switch (type) {
      case "Regular":
        namekey = `Regular.addresses.${selectedAsset.asset}`;
        break;
      case "Signals":
        namekey = `Signals.addresses.${selectedAsset.asset}`;
      default:
        break;
    }

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: url,
          address,
          name: selectedAsset.name,
          symbol: selectedAsset.asset,
          //   tag: tag || null,
          //   memo: memo || null,
          //   note: note || null,
          //   network: network || null,
        },
      });
      if (type === "Signals") {
        switchType();
      } else if (type === "Regular") {
        setIsSaving(false);
        toast.success("Successfully added address");

        // console.log("saved");
      } else {
        setIsSaving(false);
        toast.error("Could not save. Please try again later");
        // console.log("saved");
      }

      // toast.success("Updated successfully.");
      // setIsEditing(false);
    } catch (error) {
      console.log(error);
      toast.error("Could not save. Please try again later");

      // toast.error("Could not update. Please try again later");
      setIsSaving(false);
    }
  }

  async function addDepositAddress() {
    // const namekey = `Regular.addresses.${selectedAsset.asset}`;

    let namekey;

    switch (type) {
      case "Regular":
        namekey = `Regular.addresses.${selectedAsset.asset}`;
        break;
      case "Signals":
        namekey = `Signals.addresses.${selectedAsset.asset}`;
      default:
        break;
    }

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: null,
          address,
          name: selectedAsset.name,
          symbol: selectedAsset.asset,
          //   tag: tag || null,
          //   memo: memo || null,
          //   note: note || null,
          //   network: network || null,
        },
      });

      if (type === "Signals") {
        switchType();
      } else if (type === "Regular") {
        setIsSaving(false);
        toast.success("Successfully added address");

        // console.log("saved");
      } else {
        setIsSaving(false);
        toast.success("Successfully added address");

        // console.log("saved");
      }
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      toast.error("Could not add. Please try again later");
    }
  }

  async function switchType() {
    const namekey = `Signals.type`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: "manual",
      });
      setIsSaving(false);
      // console.log("saved");

      toast.success("Successfully added address");

      setTimeout(() => {
        setAddAddress(false);
      }, 600);
      // toast.success("Updated successfully.");
      // setIsEditing(false);
    } catch (error) {
      setIsSaving(false);
      // console.log(error);
      toast.error("Could not add. Please try again later");
      // toast.error("Could not update. Please try again later");
    }
  }

  return (
    <Modal
      open={addAddress}
      onClose={() => setAddAddress(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          {/*  */}
          <SubText>Add Address</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setAddAddress(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div
            className="assetSelect"
            style={{
              boxSizing: "border-box",
            }}
          >
            <label htmlFor="asset">Asset</label>
            <div
              className="selectBox"
              style={{
                boxSizing: "border-box",
                width: "100%",
              }}
            >
              <div className="selectTop">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                    gap: "0.5rem",
                    boxSizing: "border-box",
                    padding: "1rem",
                  }}
                >
                  <img
                    src={`./asseticons/${selectedAsset.asset}.svg`}
                    alt="icon"
                    srcset=""
                    style={{ position: "absolute", left: "0" }}
                  />
                  <select
                    name="asset"
                    style={{
                      padding: "0",
                      width: "100%",
                      boxSizing: "border-box",
                      display: "flex",
                      marginLeft: "30px",
                    }}
                    onChange={handleAssetChange}
                  >
                    {cryptoAssets.sort().map((asset) => (
                      <option value={asset.asset} key={asset.name}>
                        {asset.name}
                      </option>
                    ))}
                  </select>
                </div>
                <img
                  src="/extraicons/arrowdown.svg"
                  alt="select"
                  className="dropDownIcon"
                />
              </div>
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Address</label>
            <div className="add">
              <input placeholder="Address" onChange={handleAddress} />
            </div>
          </div>

          <div
            className="qr-code"
            style={{
              margin: "40px 0px",
              height: "250px",
              border: "1px solid #222739",
              borderRadius: "12px",
              display: "grid",
              cursor: "pointer",
              placeContent: "center",
              margin: "40px 0px",
              position: "relative",
            }}
            onClick={handleImageChange}
          >
            {/* <img
                    src="./assets/misc/info.svg"
                    alt=""
                    className="error_inform"
                    id="popcorn"
                    style={{
                      position: "absolute",
                      right: "-30px",
                    }}
                  /> */}

            {tempUrl && (
              <div
                style={{
                  maxHeight: "250px",
                  overflow: "hidden",
                  padding: "8px",
                  height: "100%",
                }}
              >
                <img
                  style={{ margin: "0px", backgroundColor: "red" }}
                  src={tempUrl}
                  alt=""
                  className="qr-code"
                />
              </div>
            )}
            {!tempUrl && (
              <p
                style={{
                  padding: "8px",
                  color: "white",
                  lineHeight: "20px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#d4dcf7",
                }}
              >
                Tap here to add or change QR code. Leave empty to save address
                without QR code.
              </p>
            )}

            <input
              type="file"
              style={{
                opacity: "0",
                position: "absolute",
                pointerEvents: "none",
                top: "0",
                left: "0",
                height: "100%",
                maxWidth: "100%",
              }}
              ref={imageRef}
              onChange={handleImageURL}
              accept="image/png, image/gif, image/jpeg, image/svg"
            />
          </div>

          <div
            className="buttons"
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <button
              className={
                isSaving || !selectedAsset || !address
                  ? "button disabled"
                  : "button submit"
              }
              disabled={isSaving || !selectedAsset || !address}
              onClick={handleSave}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isSaving ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Save</p>
              )}
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }

  .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  
`;

export default AddAddressModal;
