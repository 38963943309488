import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, OutlinedBox, SubText } from "../styles";
import styled from "styled-components";
import { userContext } from "../context/userContext";
import CircularLoader from "../pages/CircularLoader";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

const DepositCoinbaseModal = ({ open, user }) => {
  const { depositCoinbase, setDepositCoinbase } = open;
  const { accounts, currentPrices } = useContext(userContext);
  const [fiatAccount, setFiatAccount] = useState({});
  const [amount, setAmount] = useState("");
  const [isPaying, setIsPaying] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [minimumError, setMinimumError] = useState(false);
  const AmountRef = useRef();
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (amount < 10) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    // if (amount > 500 && amount < 300) {
    //   setMinimumError(true);
    // } else {
    //   setMinimumError(false);
    // }
  }, [amount]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Fiat } = live;
        setFiatAccount(Fiat);
      }
    }
  }, [accounts]);

  async function createCharge(ref, user, amount) {
    await setDoc(doc(db, "deposits", ref), {
      ref,
      user: user.uid,
      status: "pending",
      date: serverTimestamp(),
      amount: Number(amount),
      type: "USD",
      method: "Coinbase",
    })
      .then(() => {
        createCoinbaseCharge(user, amount, ref);
      })
      .catch((error) => console.log("error", error));
  }

  async function handleCoinbasePayment() {
    setIsPaying(true);
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      user.uid.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    createCharge(str, user, amount);
  }

  async function createCoinbaseCharge(user, amount, str) {
    const url = "https://api.commerce.coinbase.com/charges";

    const base = {
      name: "Prime Fintech",
      description:
        "Trade Cryptocurrencies, Stocks, & Forex on a Recognized Trading Platform.",
      local_price: {
        amount: String(Number(amount).toFixed(2)),
        currency: "USD",
      },
      pricing_type: "fixed_price",
      metadata: {
        user: user.uid,
        amount: String(Number(amount)),
        ref: str,
        asset: "USD",
      },
      redirect_url: "https://app.primefintech.net/deposit",
      cancel_url: "https://app.primefintech.net/deposit",
    };

    const config = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-CC-Api-Key": "c3233b32-4327-42e9-bbd1-41b2c4299ec6",
        "X-CC-Version": "2018-03-22",
      },
      body: JSON.stringify(base),
    };

    try {
      const response = await fetch(url, config);
      if (response) {
        const data = await response.json();
        // console.log(data);
        const hosted_url = data.data.hosted_url;
        window.location.replace(hosted_url);
        setIsPaying(false);
        setIsWaiting(true);
      }
    } catch (error) {
      console.log("Error with coinbase commerce");
      console.log(error);
      setIsPaying(false);
    }
  }

  return (
    <Modal
      open={depositCoinbase}
      onClose={() => setDepositCoinbase(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Pay with Crypto</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setDepositCoinbase(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          {isWaiting ? (
            <CircularLoader />
          ) : (
            <>
              <OutlinedBox>
                <p className="infot">
                  This deposit will be available in your live USD account.
                </p>
              </OutlinedBox>
              <br />

              <div className="amWrap">
                <label htmlFor="amount">Amount</label>
                <div className="amBx">
                  <div className="amTop">
                    <input
                      placeholder="1000"
                      name="amount"
                      type="number"
                      ref={AmountRef}
                      onChange={handleAmount}
                    />
                    <span className="coinSelector">
                      <img src={`/asseticons/USD.svg`} alt="coin" />
                      <p>USD</p>
                    </span>
                  </div>

                  <div className="captions">
                    <p className="balance">
                      Balance ~ {""}
                      <span className={fiatAccount.value < 1 ? "red" : "green"}>
                        ${Math.floor(fiatAccount.value)}
                      </span>
                    </p>
                    {amount && (
                      <p className="extra">
                        Total
                        <span className="green">
                          {" "}
                          ~$
                          {Math.floor(currentPrices["USD"] * amount).toFixed(2)}
                        </span>
                      </p>
                    )}
                  </div>
                  <br />
                  {minimumError && (
                    <p
                      className="red warning textMedium"
                      style={{ marginTop: "1rem" }}
                    >
                      The minimum deposit is $300
                    </p>
                  )}
                </div>
              </div>

              <br />

              <button
                style={{ display: "grid", placeContent: "center" }}
                disabled={!amount || minimumError || isPaying || isDisabled}
                onClick={handleCoinbasePayment}
                className={
                  !amount || minimumError || isPaying || isDisabled
                    ? "button disabled"
                    : "button submit"
                }
              >
                {isPaying ? (
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                ) : (
                  <p style={{ textDecoration: "none", color: "white" }}>
                    Pay with Crypto
                  </p>
                )}
              </button>
            </>
          )}
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default DepositCoinbaseModal;
