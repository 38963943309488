import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import {
  deleteField,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { useRef } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const EditAddressModal = ({ open, type, current, admin }) => {
  const { editAddress, setEditAddress } = open;
  const [isChanging, setIsChanging] = useState(false);

  const [name, setName] = useState(admin ? type : current.name || null);
  const [symbol, setSymbol] = useState(admin ? admin : current.symbol || null);
  const [address, setAddress] = useState(
    admin ? current : current.address || null
  );
  const [qr, setQR] = useState(current.QR || null);
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const [tempUrl, setTempUrl] = useState("");

  const imageRef = useRef();

  function handleImageChange() {
    imageRef.current.click();
  }

  const [isSaving, setIsSaving] = useState(false);

  async function handleSave() {
    setIsSaving(true);

    if (imageLink) {
      submitQR();
    } else {
      handleEditAddress();
    }
  }

  async function submitQR() {
    // setIsUploadingVerification(true);
    if (imageLink) {
      const storageRef = ref(storage, imageName + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            // submitVerification(downloadURL);
            handleEditAddressQR(downloadURL);
          });
        }
      );
    }
  }

  async function handleEditAddressQR(url) {
    let namekey;

    if (!admin) {
      switch (type) {
        case "Regular":
          namekey = `Regular.addresses.${symbol}`;
          break;
        case "Signals":
          namekey = `Signals.addresses.${symbol}`;
        default:
          break;
      }
    } else {
      namekey = `Regular.addresses.${symbol}`;
    }

    // const namekey = type === "Regular "`Regular.addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: url,
          address,
          name,
          symbol,
          //   tag: tag || null,
          //   memo: memo || null,
          //   note: note || null,
          //   network: network || null,
        },
      });
      switchType();
    } catch (error) {
      setIsSaving(false);
      //   console.log(error);
      toast.error("Could not save. Please try again later");

      // toast.error("Could not update. Please try again later");
    }
  }

  async function handleEditAddress() {
    let namekey;

    if (!admin) {
      switch (type) {
        case "Regular":
          namekey = `Regular.addresses.${symbol}`;
          break;
        case "Signals":
          namekey = `Signals.addresses.${symbol}`;
        default:
          break;
      }
    } else {
      namekey = `Regular.addresses.${symbol}`;
    }

    // const namekey = type === "Regular "`Regular.addresses.${symbol}`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: {
          QR: qr,
          address,
          name,
          symbol,
          //   tag: tag || null,
          //   memo: memo || null,
          //   note: note || null,
          //   network: network || null,
        },
      });
      switchType();
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      toast.error("Could not save. Please try again later");
    }
  }

  function handleAddress(e) {
    const value = e.target.value;

    if (value) {
      setAddress(value);
    } else {
      setAddress(address);
    }
  }

  async function switchType() {
    const namekey = `Signals.type`;

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: "manual",
      });
      setIsSaving(false);
      // console.log("saved");
      toast.success("Successfully edited address");

      setTimeout(() => {
        setEditAddress(false);
      }, 600);
      // console.log("error", error);
    } catch (error) {
      // console.log(error);
      setIsSaving(false);

      toast.error("Could not save. Please try again later");

      // console.log("error", error);
    }
  }

  // delete
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    setIsDeleting(true);

    let namekey;

    switch (type) {
      case "Regular":
        namekey = `Regular.addresses.${symbol}`;
        break;
      case "Signals":
        namekey = `Signals.addresses.${symbol}`;
      default:
        break;
    }

    const q = doc(db, "admin", "deposit");
    try {
      await updateDoc(q, {
        [namekey]: deleteField(),
      });

      setIsDeleting(false);
      toast.success("Deleted successfully");
      setTimeout(() => {
        setEditAddress(false);
      }, 600);
      // switchType();
    } catch (error) {
      setIsDeleting(false);
      toast.error("Could not delete. Please try again later");
    }
  }

  async function handleChange() {
    setIsChanging(true);

    const docRef = doc(db, "admin", "addresses");
    const key = `${type}.address`;

    await updateDoc(docRef, {
      [key]: address,
    })
      .then(() => {
        toast.success("Changed successfully");
        setIsChanging(false);
        setTimeout(() => {
          setEditAddress(false);
        }, 400);
      })
      .catch((error) => {
        toast.error("Please try again later");
        setIsChanging(false);
      });
  }

  function handleAddress(e) {
    const value = e.target.value;
    if (value) {
      setAddress(value);
    } else {
      setAddress(current);
    }
  }

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
      const url = URL.createObjectURL(file);
      setTempUrl(url);
    }
  }

  return (
    <Modal
      open={editAddress}
      onClose={() => setEditAddress(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          {/*  */}
          <SubText>Edit {admin ? type : current.symbol} Address</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setEditAddress(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div className="addcon">
            <label htmlFor="new">Address</label>
            <div className="add">
              <input
                placeholder={admin ? current : current.address}
                onChange={handleAddress}
              />
            </div>
          </div>

          <div
            className="qr-code"
            style={{
              margin: "40px 0px",
              height: "250px",
              border: "1px solid #222739",
              borderRadius: "12px",
              display: "grid",
              cursor: "pointer",
              placeContent: "center",
              margin: "40px 0px",
              position: "relative",
            }}
            onClick={handleImageChange}
          >
            {/* <img
                    src="./assets/misc/info.svg"
                    alt=""
                    className="error_inform"
                    id="popcorn"
                    style={{
                      position: "absolute",
                      right: "-30px",
                    }}
                  /> */}

            {qr && !tempUrl && (
              <span
                style={{
                  maxHeight: "250px",
                  overflow: "hidden",
                  padding: "8px",
                  height: "100%",
                }}
              >
                <img
                  style={{ margin: "0px" }}
                  src={qr}
                  alt=""
                  className="qr-code"
                />
              </span>
            )}

            {qr && tempUrl && (
              <span
                style={{
                  maxHeight: "250px",
                  overflow: "hidden",
                  padding: "8px",
                  height: "100%",
                }}
              >
                <img
                  style={{ margin: "0px" }}
                  src={tempUrl}
                  alt=""
                  className="qr-code"
                />
              </span>
            )}

            {!qr && tempUrl && (
              <span
                style={{
                  maxHeight: "250px",
                  overflow: "hidden",
                  padding: "8px",
                  height: "100%",
                }}
              >
                <img
                  style={{ margin: "0px", objectFit: "cover" }}
                  src={tempUrl}
                  alt=""
                  className="qr-code"
                />
              </span>
            )}

            {!qr && !tempUrl && (
              <p
                style={{
                  padding: "8px",
                  color: "white",
                  lineHeight: "20px",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#d4dcf7",
                }}
              >
                Tap here to add or change QR code. Leave empty to save address
                without QR code.
              </p>
            )}

            <input
              type="file"
              style={{
                opacity: "0",
                position: "absolute",
                pointerEvents: "none",
                top: "0",
                left: "0",
                height: "100%",
                maxWidth: "100%",
              }}
              ref={imageRef}
              onChange={handleImageURL}
              accept="image/png, image/gif, image/jpeg, image/svg"
            />
          </div>

          <div
            className="buttons"
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <button
              className={
                isSaving || isDeleting ? "button disabled" : "button delete"
              }
              disabled={isSaving || isDeleting}
              onClick={handleDelete}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
                backgroundColor: isDeleting ? "grey" : "#E64B60",
              }}
            >
              {isDeleting ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Delete</p>
              )}
            </button>

            <button
              className={
                isSaving || isDeleting ? "button disabled" : "button submit"
              }
              disabled={isSaving || isDeleting}
              onClick={handleSave}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isChanging ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Save</p>
              )}
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default EditAddressModal;
